exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-koiraspesialistin-pentuopas-tsx": () => import("./../../../src/pages/koiraspesialistin-pentuopas.tsx" /* webpackChunkName: "component---src-pages-koiraspesialistin-pentuopas-tsx" */),
  "component---src-pages-kuka-olen-tsx": () => import("./../../../src/pages/kuka-olen.tsx" /* webpackChunkName: "component---src-pages-kuka-olen-tsx" */),
  "component---src-pages-yhteys-tsx": () => import("./../../../src/pages/yhteys.tsx" /* webpackChunkName: "component---src-pages-yhteys-tsx" */),
  "component---src-templates-article-list-tsx": () => import("./../../../src/templates/articleList.tsx" /* webpackChunkName: "component---src-templates-article-list-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

